@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Pixel Powerline;
    src: url("../src/assets/fonts/Pixel Powerline.ttf");
  }
}

h1 {
  @apply font-pixel text-xl;
}

h2 {
  @apply font-pixel text-lg;
}

p {
  @apply font-titillium sm:text-lg leading-relaxed text-sm;
}

.heading-title {
  @apply font-pixel text-3xl text-brand-blue-100;
}

.button-primary {
  @apply bg-brand-blue-100 px-4 py-3 rounded-sm text-brand-blue-900 font-titillium;
}

.snap-child {
  @apply snap-start snap-always  flex flex-col h-full w-full justify-center items-center;
}

.snap-parent {
  @apply snap-y snap-mandatory h-full  overflow-y-scroll overflow-x-hidden;
}

.photo-main {
  @apply bg-brand-blue-100 sm:p-20 p-10;
}

.photo-alt {
  @apply bg-brand-blue-900 sm:p-20 p-10;
}
/* @font-face {
  font-family: "PixelPowerline";
  src: local("PixelPowerline"),
    url(./assets/fonts/PixelPowerline.ttf) format("ttf");
} */
